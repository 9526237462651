<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Client',
  data() {
    return {}
  }
}
</script>
